class EtcFilesInfoModel {
  constructor(obj) {
    //this.attachFileList = [];
    (this.attachFile = {}), (this.attacheId = 0), (this.comments = "");
    this.mode = "";
    this.attacheType = "I";

    if (obj) {
      this.setData(obj);
    }
  }

  setData(obj) {
    if (obj.attachFile) this.attachFile = obj.attachFile;
    if (obj.attacheId) this.attacheId = obj.attacheId;
    if (obj.comments) this.comments = obj.comments;
    if (obj.mode) this.mode = obj.mode;
    if (obj.attacheType) this.attacheType = obj.attacheType;
  }

  getData() {
    // if(this.attachFileList && this.attachFileList.length > 0 ) {
    //     this.attachFile = this.attachFileList[0];
    // }

    let obj = {
      attachFile: this.attachFile,
      attachId: this.attacheId,
      comments: this.comments,
      mode: this.mode,
      attacheType: this.attacheType,
    };

    return obj;
  }
}

export default EtcFilesInfoModel;
